import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/b7146c2/components/layout-content.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Colors`}</h1>
    <p><a parentName="p" {...{
        "href": "/api/generate/colors"
      }}><inlineCode parentName="a">{`/api/generate/colors`}</inlineCode></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      